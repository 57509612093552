import axios from './../config/axios'

export default {
    login: function (data) {
        return axios({
            url: "/api/user/login",
            method: 'post',
            data: data
        });
    },
    requestResetPassword: function (data) {
        return axios({
            url: "/api/user/password/reset/request",
            method: 'post',
            data: data
        });
    },
    resetPassword: function (data) {
        return axios({
            url: "/api/user/password/reset",
            method: 'post',
            data: data
        });
    },
    register: function (data) {
        return axios({
            url: "/api/user/register",
            method: 'post',
            data: data
        });
    },
    active: function (data) {
        return axios({
            url: "/api/user/active",
            method: 'post',
            data: data
        });
    },
}
