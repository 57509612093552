<template>
    <div>
        <SuccessDialog v-model="popup.success.show"
                       :title="'title'"
                       :message="'message'"
                       @close="$router.push('/login')"/>
    </div>
</template>

<script>
import UserApi from "@/api/UserApi";
import SuccessDialog from "@/components/SuccessDialog";

export default {
    name: "RegisterConfirm",
    components: {SuccessDialog},
    mounted() {
        this.activeAccount()
    },
    data() {
        return {
            popup: {
                success: {
                    show: false
                }
            }
        }
    },
    methods: {
        activeAccount: function () {
            UserApi.active({
                token: this.$route.query.token
            }).then(resp => {
                console.log(resp)
                this.popup.success.show = true
            })
        }
    }

}
</script>

<style scoped>

</style>